import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Image
import searchIcon from '../../images/search-icon.svg';
// Styles
import { Wrapper, Content } from './DatePicker.styles';



const DatePicker = ({setDate})=>{

  const [state,setState] = useState("");

  useEffect(()=>{
    const timer = setTimeout(()=>{
      setDate(state)
    },500)

    return ()=> clearTimeout(timer)
  },[setDate,state])

  return(
     <Wrapper>
      <Content>
        
        <input 
          type="date" 
          placeholder='date'
          onChange={event => setState(event.currentTarget.value)}
          value={state}  
          />
      </Content>

     </Wrapper>

  );

};

export default DatePicker;
