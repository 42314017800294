import React, { useState, useEffect } from 'react';
// Config
import { POSTER_SIZE, BACKDROP_SIZE, IMAGE_BASE_URL } from '../config';
// Components
import Header from './Header';
import Grid from './Grid';
import Thumb from './Thumb';
import SearchBar from './SearchBar';
import DatePicker from './DatePicker';
import DateWrapper from './DateWrapper';

// Hook
import { useHomeFetch } from '../hooks/useHomeFetch';
// Image
import NoImage from '../images/no_image.jpg';
import { Link } from 'react-router-dom';

const Home = () => {
  const { state, loading, error, setSearchTerm, setStartDate,setEndDate} = useHomeFetch();

  console.log(state.results);
 
  

  return (
    <>
      <Header></Header>
      <SearchBar setSearchTerm={setSearchTerm}/>
      
      <DateWrapper>
        <DatePicker setDate={setStartDate}/>
          <div>tot</div>
        <DatePicker setDate={setEndDate}/>

      </DateWrapper>

      
      
      
      <Grid header='Besluite'>
        {error?"error to connect to server":""}
        {state.results.map(movie => (

          
          <Thumb
            key={movie._id}
            clickable
            title={movie.voorstel}
            aksiespan={movie.aksiespan}
            besluit={movie.besluit}
            datum={movie.datum.toString().substring(0,10)}
            movieId={movie._id}
        
          />
      
        ))}
      </Grid>
    </>
  );
};

export default Home;
