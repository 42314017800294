import React from 'react';

import {BrowserRouter as Router, Routes,Route} from 'react-router-dom'

// import Header from './components/Header';
import Home from './components/Home';
import Besluit from './components/Besluit'


import {GlobalStyle} from './GlobalStyle';

function App() {
  return (
    <div>
      
      <Router>
          <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/:movieId" element={<Besluit/>}/>
          </Routes>
      </Router> 

      <GlobalStyle/>
    
    </div>
  );
}

export default App;
