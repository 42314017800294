import styled from "styled-components";

export const Wrapper = styled.div`
 position: sticky;
 top: 180px;
 z-index: 2;
    

`

export const Content = styled.div`
    position:relative;
    display: grid;
    grid-auto-flow:column;
   
    grid-template-columns: 5fr 1fr 5fr;
`