import { useState, useEffect, useRef } from 'react';
// API
import API from '../API';

const initialState = {
  page: 0,
  results: [],
  total_pages: 0,
  total_results: 0
};

export const useHomeFetch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  
  console.log(searchTerm)
  const fetchMovies = async (page, searchTerm, startDate,endDate) => {
    try {


      console.log("Inside fetchMovies")
      setError(false);
      setLoading(true);
      console.log("Before API fetchMovies")
      console.log(searchTerm)
      console.log(startDate)
      console.log(endDate)

      const movies = await API.fetchMovies(searchTerm, page, startDate,endDate);
      console.log(movies)
      console.log("After API fetchMovies")
     
      

      setState(prev => ({
        ...movies,
        results:
          page > 1 ? [...prev.results, ...movies.besluite] : [...movies.besluite]
      }));
    } catch (error) {
      console.log(error)
      setError(true);
    }
    setLoading(false);
  };

  // Initial render
  useEffect(() => {
    setState(initialState)
    fetchMovies(1,searchTerm,startDate,endDate);
  }, [searchTerm,startDate,endDate]);

  return { state, loading, error, setSearchTerm, setStartDate,setEndDate };
};
