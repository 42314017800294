import React from "react";

import { Wrapper,Content } from "./DateWrapper.styles";

const DateWrapper = ({children})=>{

    return(
        <Wrapper>
            <Content>{children}</Content>
        </Wrapper>

    )

}

export default DateWrapper;