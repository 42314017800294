import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Image
import searchIcon from '../../images/search-icon.svg';
// Styles
import { Wrapper, Content } from './SearchBar.styles';



const SearchBar = ({setSearchTerm})=>{

  const [state,setState] = useState("");

  useEffect(()=>{
    const timer = setTimeout(()=>{
      setSearchTerm(state)
    },500)

    return ()=> clearTimeout(timer)
  },[setSearchTerm,state])

  return(
     <Wrapper>
      <Content>
        <img src={searchIcon}/>
        <input 
          type="text" 
          placeholder='Soek'
          onChange={event => setState(event.currentTarget.value)}
          value={state}  
          />
      </Content>

     </Wrapper>

  );

};

export default SearchBar;
