import styled from 'styled-components'
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
    border-style: inset;
    border-color: aliceblue;
    border-radius: 5px;
    
    
    grid-template-columns: 5fr 1fr 1fr 1fr;
    box-shadow: black 20px 7px 29px 0px;
    background-color: aliceblue;

`

export const Image = styled.img`

    width: 100%;
    max-width: 200px;
    transition: all 2s;
    object-fit: cover;
    border-radius: 40px;

    :hover {
        transform: rotateY(360deg);
        opacity: 80%;
    }

`;

export const Content = styled.div`
    position: relative;
    padding: 5px;
    font-size: large;
    font-family: 'Times New Roman', Times, serif;
`;
export const StyledLink = styled(Link)`
    text-decoration: none;
    color: black;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        cursor: pointer;
    }
`;