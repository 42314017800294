import styled from "styled-components";
import { Link } from "react-router-dom";
export const Wrapper = styled.div`
    
`
export const Content = styled.div`
    
    border-radius: 10px;
    box-shadow: ${props => (props.switchOn ? '0 5px 9px white inset':'0 5px 9px white inset, 0 5px 9px grey' )};
    padding: 15px;
    background: rgba(182, 200, 202, 0.97);
    text-decoration:none;
    color: white;
    text-align: center;
    align-items: center;
    
    width: ${props=> (props.list?'50px':'')}

`
export const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        cursor: pointer;
    }
`