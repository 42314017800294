import React from "react";
import { Wrapper, Content,StyledLink } from "./Knoppie.styles";
import { Link } from "react-router-dom";


const Knoppie = ({destination,clickable}) =>(
<Wrapper>
    
    <Content >
        
        {clickable?(
            <StyledLink to="/">
                <Content>
                {destination}

                </Content>
            </StyledLink>
            
            
        ):
        (<div>{destination}</div>)
        
        }
   
    </Content>

</Wrapper>


);

export default Knoppie;