import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 100px;
  height: 100px;
  background: var(--Grey);
  padding: 0 20px;
  z-index:1;
`;

export const Content = styled.div`
  position: relative;
 
  width: 100%;
  height: 55px;
  background: var(--medGrey);
  margin: 0 auto;
  border-radius: 40px;
  color: var(--white);

  img {
    position: absolute;
    left: 15px;
    top: 14px;
    width: 30px;
  }

  input {
    font-size: var(--fontBig);
    position: absolute;
    left: 0;
    margin: 8px 0;
    padding: 0 0 0 60px;
    border: 0;
    width: 95%;
   
    background: transparent;
    height: 40px;
    color: var(--white);

  }
  :focus {
    outline: none;
    border: 0;
  }
`;
