import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Config
import { POSTER_SIZE, BACKDROP_SIZE, IMAGE_BASE_URL } from '../config';
// Components
import Header from './Header';
import Thumb from './Thumb';
import Knoppie from './Knoppie';


// Hook
import { useBesluitFetch } from '../hooks/useBesluitFetch';
// Image
import NoImage from '../images/no_image.jpg';



const Besluit = () => {
  const{movieId}=useParams()
  console.log(movieId)
  const { state: besluit, loading, error} = useBesluitFetch(movieId);

  // console.log(besluit.besluit[0].besluit)
  console.log(besluit)
  return (
    <>
      
      <Header></Header>
      <Knoppie
      destination={"Terug"}
      clickable
      />
      <Thumb
          voorstel={besluit.voorstel}
          aksiespan={besluit.aksiespan}
          beskrywing={besluit.beskrywing}
          besluit={besluit.besluit}
          datum={besluit.datum}

        />      
    </>
  );
};

export default Besluit;
