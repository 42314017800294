import { useState, useEffect, useRef } from 'react';
// API
import API from '../API';

export const useBesluitFetch = movieId => {
  
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  
  console.log("binne use Besluit fetch")
  console.log(movieId)
  useEffect(()=>{
    console.log("binne use Besluit fetch se useEffect")
    const fetchBesluit = async()=>{
      try {
        setLoading(true);
        setError(false);
       
        const besluit = await API.fetchBesluit(movieId)
       
        setState({  
          ...besluit.besluit[0]
        }
        )

        setLoading(false)

      } catch (error) {
        console.log(error)
        setError(true);
      }
    }
    fetchBesluit()
  },[movieId]);

return{state,loading,error};


};
