import React from 'react';

import { Wrapper,Image, Content, StyledLink} from "./Thumb.styles";
import { Link } from "react-router-dom";

const Thumb = ({ image, movieId, title,voorstel,beskrywing,aksiespan,besluit,datum, clickable}) => (

    <div>
        
        {clickable?(
            <Content>

            <StyledLink to={`/${movieId}`} state={{itemid: movieId}}>
                <Wrapper>
                <Content>{title}</Content>
                <Content>{aksiespan}</Content>
                <Content>{besluit}</Content>
                <Content>{datum}</Content>

                </Wrapper>
            </StyledLink>

            </Content>
        ):
        <Wrapper>
        
        <Content><div>Voorstel:</div>{voorstel}</Content>
        <Content><div>Beskrywing:</div>{beskrywing}</Content>
        <Content><div>Aksiespan:</div>{aksiespan}</Content>
        <Content><div>Besluit:</div>{besluit}</Content>
        <Content><div>Datum:</div>{datum}</Content>

       </Wrapper>
        }

        
    </div>

);


    
export default Thumb;
