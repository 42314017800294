import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  width: 100%;
  top: 150px;
  height: 30px;
  
  padding: 0 20px;
  z-index:1;
`;

export const Content = styled.div`
  position: relative;
 
  width: 100%;
  height: 100%;
  background: rgba(182, 200, 202, 0.97);
  margin: 0 auto;
  border-radius: 40px;
  color: var(--white);
  input {
    
    position: absolute;
    left: 0;
    
    padding: 0 0 0 20px;
    border: 0;
    width: 95%;
   
    background: transparent;
    height: 30px;
    color: var(--white);

  }
  :focus {
    outline: none;
    border: 0;
  }
`;
