import styled from 'styled-components';


export const Wrapper = styled.div`
    background: linear-gradient(120deg, rgba(40, 72, 95, 0.97) 30%, rgba(182, 200, 202, 0.97) 140%);
    padding: 0 20px;
    position:sticky;
    top:0px;
    z-index:1;
`;


export const Content = styled.div`
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
    
    padding: 20px 0;
    margin: 0 auto;

`;


export const LogoImg = styled.img`
width: 200px;

@media screen and (max-width: 500px) {
    width: 150px;
}
`;


export const TMDBLogoImg = styled.img`
width: 100px;

@media screen and (max-width: 500px) {
    width: 80px;
}

`;

