import React from 'react';

import RMDBLogo from '../../images/Strand-Noord-NG-Gemeente-Logo-Transparent-Background-Website.png';


import { Wrapper,Content, LogoImg} from './Header.styles';

const Header = () => (

 < Wrapper>
    <Content>
        <LogoImg src= {RMDBLogo} alt = "rmdb-logo" />
        <div>Besluiteregister</div>
        <div></div>
        

    </Content>

 </Wrapper>

)

export default Header;